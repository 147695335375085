import { lazy } from "react";
const Home = lazy(() => import("pages/home"));
const Categories = lazy(() => import("pages/categories"));
const AddCategory = lazy(() => import("pages/categories/AddCategory"));
const EditCategory = lazy(() => import("pages/categories/EditCategory"));
const CategoriesLayout = lazy(() => import("pages/categories/CategoriesLayout"));
const Subcategories = lazy(() => import("pages/subcategories"));
const AddSubcategory = lazy(() => import("pages/subcategories/AddSubcategory"));
const EditSubcategory = lazy(() => import("pages/subcategories/EditSubcategory"));
const SubcategoriesLayout = lazy(() => import("pages/subcategories/SubcategoriesLayout"));
const Innercategories = lazy(() => import("pages/innercategories"));
const AddInnercategory = lazy(() => import("pages/innercategories/AddInnercategory"));
const EditInnercategory = lazy(() => import("pages/innercategories/EditInnercategory"));
const InnerCategoriesLayout = lazy(() => import("pages/innercategories/InnercategoriesLayout"));
const Brands = lazy(() => import("pages/brands"));
const AddBrand = lazy(() => import("pages/brands/AddBrand"));
const EditBrand = lazy(() => import("pages/brands/EditBrand"));
const BrandsLayout = lazy(() => import("pages/brands/BrandsLayout"));
const Users = lazy(() => import("pages/users"));
const AddUser = lazy(() => import("pages/users/AddUser"));
const UsersLayout = lazy(() => import("pages/users/UsersLayout"));
const ShoppingGuidesLayout = lazy(() => import("pages/shoppingGuides/ShoppingGuidesLayout"));
const ShoppingGuides = lazy(() => import("pages/shoppingGuides"));
const AddShoppingGuide = lazy(() => import("pages/shoppingGuides/AddShoppingGuide"));
const EditShoppingGuide = lazy(() => import("pages/shoppingGuides/EditShoppingGuide"));



const NotFound = lazy(() => import("pages/notFound"));

// const Login = lazy(() => import("pages/login"));

export interface IRoute {
  path: string;
  key?: string | "*";
  element: JSX.Element;
  inner?: IRoute[];
  index?: boolean;
  title: string;
}

const privateRoutes: IRoute[] = [
  {
    path: "/",
    key: "Home",
    title: "Home",
    element: <Home />,
  },
  {
    path: "/categories",
    key: "CategoriesLayout",
    title: "Categories Layout",
    element: <CategoriesLayout />,
    inner: [
      {
        path: "",
        key: "Categories",
        title: "Categories",
        element: <Categories />,
      },
      {
        path: "add",
        key: "AddCategory",
        title: "Add Category",
        element: <AddCategory />,
      },
      {
        path: "edit/:id",
        key: "EditCategory",
        title: "Edit Category",
        element: <EditCategory />,
      },
    ],
  },
  {
    path: "/subcategories",
    key: "SubcategoriesLayout",
    title: "Subcategories Layout",
    element: <SubcategoriesLayout />,
    inner: [
      {
        path: "",
        key: "Subcategories",
        title: "Subcategories",
        element: <Subcategories />,
      },
      {
        path: "add",
        key: "AddSubcategory",
        title: "Add Subcategory",
        element: <AddSubcategory />,
      },
      {
        path: "edit/:id",
        key: "EditSubcategory",
        title: "Edit Subcategory",
        element: <EditSubcategory />,
      },
    ],
  },
  {
    path: "/innercategories",
    key: "InnerCategoriesLayout",
    title: "InnerCategoriesLayout",
    element: <InnerCategoriesLayout />,
    inner: [
      {
        path: "",
        key: "InnerCategories",
        title: "InnerCategories",
        element: <Innercategories />,
      },
      {
        path: "add",
        key: "AddInnercategory",
        title: "Add Innercategory",
        element: <AddInnercategory />,
      },
      {
        path: "edit/:id",
        key: "EditInnercategory",
        title: "Edit Innercategory",
        element: <EditInnercategory />,
      },
    ],
  },
  {
    path: "/brands",
    key: "brandsLayout",
    title: "Brands Layout",
    element: <BrandsLayout />,
    inner: [
      {
        path: "",
        key: "Brands",
        title: "Brands",
        element: <Brands />,
      },
      {
        path: "add",
        key: "AddBrand",
        title: "Add Brand",
        element: <AddBrand />,
      },
      {
        path: "edit/:id",
        key: "EditBrand",
        title: "Edit Brand",
        element: <EditBrand />,
      },
    ],
  },
  {
    path: "/users",
    key: "UsersLayout",
    title: "Users Layout",
    element: <UsersLayout />,
    inner: [
      {
        path: "",
        key: "Users",
        title: "Users",
        element: <Users />,
      },
      {
        path: "add",
        key: "AddUser",
        title: "Add User",
        element: <AddUser />,
      },

    ],
  },
  {
    path: "/shopping-guides",
    key: "ShoppingGuidesLayout",
    title: "Shopping Guides Layout",
    element: <ShoppingGuidesLayout />,
    inner: [
      {
        path: "",
        key: "ShoppingGuides",
        title: "ShoppingGuides",
        element: <ShoppingGuides />,
      },
      {
        path: "add",
        key: "AddShoppingGuide",
        title: "Add ShoppingGuide",
        element: <AddShoppingGuide />,
      },
      {
        path: "edit/:id",
        key: "EditShoppingGuide",
        title: "Edit ShoppingGuide",
        element: <EditShoppingGuide />,
      },

    ],
  },
  {
    path: "*",
    key: "*",
    title: "",
    element: <NotFound />,
  },

];

const publicRoutes: IRoute[] = [
  // {
  //   path: "/login",
  //   access: [],
  //   title: "Login",
  //   element: <Login />,
  // },
];

export { privateRoutes, publicRoutes };
