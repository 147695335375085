import { StateCreator } from "zustand";
import get from "lodash/get";
import { storage } from "services";

export type TTheme = "dark" | "light";

export interface ISystemInitialState {
  lang: string;
  theme: TTheme;
  loginId: string | null;
  isSidebarOpen: boolean;
  cart: any[]; // Cart state, array of items
  favorites: any[]; // Favorites state, array of items
}

export const SystemInitialState: ISystemInitialState = {
  lang: storage.get("i18nextLng") || "uz",
  theme: "light",
  loginId: JSON.parse(storage.get("loginId") || "null"),
  isSidebarOpen: false,
  cart: JSON.parse(storage.get("cart") || "[]"), // Initialize cart from storage
  favorites: JSON.parse(storage.get("favorites") || "[]"), // Initialize favorites from storage
};

export interface ISystem {
  system: ISystemInitialState;
  setLang: (action: { [key: string]: any }) => void;
  changeTheme: (data: string) => any;
  setLoginId: (data: any) => any;
  toggleSidebar: () => void;
  addToCart: (item: any) => void; // Method to add item to cart
  removeFromCart: (itemId: string) => void; // Method to remove item from cart
  addToFavorites: (item: any) => void; // Method to add item to favorites
  removeFromFavorites: (itemId: string) => void; // Method to remove item from favorites
}

export const systemSlice: StateCreator<ISystem, [], []> = (set): ISystem => {
  return {
    system: SystemInitialState,
    setLang: (action: { [key: string]: any }) => {
      return set((state) => ({
        system: {
          ...get(state, "system"),
          lang: "ru",
        },
      }));
    },
    setLoginId: (action: string) => {
      //@ts-ignore
      return set((state) => {
        storage.set("loginId", JSON.stringify(action));
        return {
          system: {
            ...get(state, "system"),
            loginId: action,
          },
        };
      });
    },
    changeTheme: (action: string) => {
      return set((state: any) => ({
        system: {
          ...get(state, "system"),
          theme: action,
        },
      }));
    },
    toggleSidebar: () => {
      return set((state: any) => ({
        system: {
          ...get(state, "system"),
          isSidebarOpen: !state.system.isSidebarOpen,
        },
      }));
    },
    addToCart: (item: any) => {
      return set((state: any) => {
        const updatedCart = [...state.system.cart, item];
        storage.set("cart", JSON.stringify(updatedCart)); // Update storage
        return {
          system: {
            ...get(state, "system"),
            cart: updatedCart,
          },
        };
      });
    },
    removeFromCart: (itemId: string) => {
      return set((state: any) => {
        const updatedCart = state.system.cart.filter((item: any) => item.id !== itemId);
        storage.set("cart", JSON.stringify(updatedCart)); // Update storage
        return {
          system: {
            ...get(state, "system"),
            cart: updatedCart,
          },
        };
      });
    },
    addToFavorites: (item: any) => {
      return set((state: any) => {
        const updatedFavorites = [...state.system.favorites, item];
        storage.set("favorites", JSON.stringify(updatedFavorites)); // Update storage
        return {
          system: {
            ...get(state, "system"),
            favorites: updatedFavorites,
          },
        };
      });
    },
    removeFromFavorites: (itemId: string) => {
      return set((state: any) => {
        const updatedFavorites = state.system.favorites.filter((item: any) => item.id !== itemId);
        storage.set("favorites", JSON.stringify(updatedFavorites)); // Update storage
        return {
          system: {
            ...get(state, "system"),
            favorites: updatedFavorites,
          },
        };
      });
    },
  };
};

