import React, { useState } from "react";
import { Upload, Button, notification, Modal } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { FieldArray, FieldProps } from "formik";

interface PhotoUploadProps extends FieldProps {
  label?: string;
}

const PhotoUpload: React.FC<PhotoUploadProps> = ({
  field,
  form: { setFieldValue },
}) => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");

  const handleFileUpload = async (file: any, index: number) => {
    const formData = new FormData();
    formData.append("file", file);
    
    try {
      const response = await fetch("https://cdn.myproje.uz/api/upload-without-tokens", {
        method: "POST",
        body: formData,
      });
      const result = await response.json();

      if (response.ok && result.data) {
        setFieldValue(`photo_urls.${index}.url`, result.data.file_url);
        setFieldValue(`photo_urls.${index}.id`, result.data.file_id);
        notification.success({
          message: "File upload successful",
          description: "The file was uploaded successfully",
        });
      } else {
        notification.error({
          message: "File upload failed",
          description: result.message || "Something went wrong",
        });
      }
    } catch (error) {
      notification.error({
        message: "File upload error",
        description: (error as Error).message,
      });
    }
  };

  const handlePreview = (file: any) => {
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
  };

  const handleCancel = () => setPreviewOpen(false);

  return (
    <>
      <FieldArray name="photo_urls">
        {({ push, remove }) => (
          <div>
            <Button
              onClick={() => push({ url: "", id: Math.random() })}
              className="mb-2"
            >
              Add Photo
            </Button>
            {Array.isArray(field.value) && field.value.map((photo_url: any, index: number) => (
              <div key={index} className="flex mb-2">
                <Upload
                  beforeUpload={(file) => {
                    handleFileUpload(file, index);
                    return false;
                  }}
                  showUploadList={false}
                >
                  <Button icon={<UploadOutlined />}>Upload</Button>
                </Upload>
                <Button onClick={() => remove(index)} className="ml-2">
                  Remove
                </Button>
                {photo_url.url && (
                  <img
                    src={photo_url.url}
                    alt={`Preview ${index + 1}`}
                    style={{ width: 50, height: 50, marginLeft: 10 }}
                    onClick={() => handlePreview(photo_url)}
                  />
                )}
              </div>
            ))}
          </div>
        )}
      </FieldArray>
      <Modal open={previewOpen} footer={null} onCancel={handleCancel}>
        <img alt="preview" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </>
  );
};

export default PhotoUpload;
