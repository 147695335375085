import { LoginPhoto, Logo } from "assets/images";
import { useHooks } from "hooks";
import useStore from "store";
import "./style.scss";
import Container from "modules/container";
import { FastField } from "formik";
import { Fields } from "components";
import { Link } from "react-router-dom";

const Login = () => {
  const { get, t } = useHooks();
  const { signIn } = useStore((state) => state);
  return (
    <div className="register-login">
      <div className="rl_left">
        <img width={"100px"} src={Logo} alt="logo" />
        <Container.Form
          url="login"
          method="post"
          fields={[
            {
              name: "login",
              type: "string",
              required: true,
            },
            {
              type: "string",
              name: "password",
              required: true,
            },
          ]}
          onSuccess={(response) => {
            signIn({
              token: get(response, "data.auth_token"),
            });
          }}
          onError={(error) => {}}
        >
          {({
            //@ts-ignore
            isLoading,
            setFieldTouched,
            values,
          }) => {
            return (
              <div>
                <h1 className="title">{t("Админ-панель")}</h1>
                <p className="label">{t("Логин")}</p>
                <FastField
                  name="login"
                  type="text"
                  rootClassName=""
                  placeholder={t("Логин")}
                  component={Fields.Input}
                />
                <p className="label">{t("Пароль")}</p>
                <FastField
                  name="password"
                  type="password"
                  rootClassName=""
                  component={Fields.Input}
                  placeholder={t("*****")}
                />
                <button
                  type="submit"
                  className="submit w-full mt-[2px] bg-[#0961E5] text-[#ffffff]"
                >
                  {t("Продолжить")}
                </button>

              </div>
            );
          }}
        </Container.Form>
        <p className="login-text">
         
        </p>
      </div>
      <div className="rl_right">
        <img src={LoginPhoto} alt="" />
      </div>
    </div>
  );
};

export default Login;
