import Input from "./input";
import InputMask from "./inputMask";
import Password from "./password";
import Select from "./select";
import DatePicker from "./datePicker";
import FileUploadEmpower from "./fileUploadEmpower";
import FileUploadEmpower2 from "./fileUploadEmpower2";
import FileUploadEmpower3 from "./fileUploadEmpower3";
import FileUploadEmpower4 from "./fileUploadEmpower4";

import FileUpload from "./fileUpload";
import AsyncSelect from "./ant-async-select";
import Textarea from "./textarea";
import Switch from "./switch";
import MultiFileUploadEmpower from "./multiFileUploadEmpower";

export default {
  Input,
  InputMask,
  Password,
  Select,
  DatePicker,
  FileUpload,
  FileUploadEmpower,
  AsyncSelect,
  Textarea,
  Switch,
  MultiFileUploadEmpower,
  FileUploadEmpower2,
  FileUploadEmpower3,
  FileUploadEmpower4
};
