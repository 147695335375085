import { useHooks } from "hooks";
import React from "react";
import "./style.scss";
import { NavLink } from "react-router-dom";
import {
  Advantages,
  BrandsIcon,
  Category,
  Inspirations,
  Links,
  LogOut,
  Products,
  Shopping,
  Solutions,
  UsageRules,
  Users,
} from "assets/images/icons";

const Sidebar = () => {
  const { t } = useHooks();
  return (
    <div className="sidebar">
      <div className="sidebar_links">
        <NavLink 
          className={({ isActive }) => `sidebar_link ${isActive ? "active" : ""}`} 
          to="/products"
        >
          <Products />
          {t("Продукты")}
        </NavLink>
        <NavLink 
          className={({ isActive }) => `sidebar_link ${isActive ? "active" : ""}`} 
          to="/users"
        >
          <Users />
          {t("Пользователи")}
        </NavLink>
        <NavLink 
          className={({ isActive }) => `sidebar_link ${isActive ? "active" : ""}`} 
          to="/advantages"
        >
          <Advantages />
          {t("Преимущества")}
        </NavLink>
        <NavLink 
          className={({ isActive }) => `sidebar_link ${isActive ? "active" : ""}`} 
          to="/categories"
        >
          <Category />
          {t("Категории")}
        </NavLink>
        <NavLink 
          className={({ isActive }) => `sidebar_link ${isActive ? "active" : ""}`} 
          to="/subcategories"
        >
          <Category />
          {t("Подкатегории")}
        </NavLink>
        <NavLink 
          className={({ isActive }) => `sidebar_link ${isActive ? "active" : ""}`} 
          to="/innercategories"
        >
          <Category />
          {t("Внутренние категории")}
        </NavLink>
        <NavLink 
          className={({ isActive }) => `sidebar_link ${isActive ? "active" : ""}`} 
          to="/brands"
        >
          <BrandsIcon />
          {t("Бренды")}
        </NavLink>
        <NavLink 
          className={({ isActive }) => `sidebar_link ${isActive ? "active" : ""}`} 
          to="/rules"
        >
          <UsageRules />
          {t("Правила")}
        </NavLink>
        <NavLink 
          className={({ isActive }) => `sidebar_link ${isActive ? "active" : ""}`} 
          to="/socials"
        >
          <Links />
          {t("Ссылки")}
        </NavLink>
        <NavLink 
          className={({ isActive }) => `sidebar_link ${isActive ? "active" : ""}`} 
          to="/shopping-guides"
        >
          <Shopping />
          {t("Шоппинг-гиды")}
        </NavLink>
        <NavLink 
          className={({ isActive }) => `sidebar_link ${isActive ? "active" : ""}`} 
          to="/inspirations"
        >
          <Inspirations />
          {t("Вдохновение")}
        </NavLink>
        <NavLink 
          className={({ isActive }) => `sidebar_link ${isActive ? "active" : ""}`} 
          to="/solutions"
        >
          <Solutions />
          {t("Решения")}
        </NavLink>
      </div>
      <div>
        <NavLink 
          className={({ isActive }) => `sidebar_link ${isActive ? "active" : ""}`} 
          to="/login"
        >
          <LogOut />
          {t("Выйти")}
        </NavLink>
      </div>
    </div>
  );
};

export default Sidebar;
