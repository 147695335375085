import React, { useState } from "react";
import { Layout } from "antd";
import { Outlet } from "react-router-dom";
import { Navigation, Header, Navigation2 } from "components";
import useStore from "store";
import Sidebar from "components/sidebar";
import "./style.scss";

const App: React.FC = () => {
  return (
    <div className="container-wrapper">
      <Layout className="h-full container">
        <Header />
        <div className="main_contents">
          <Sidebar />
          <div className="main_cont">
            <Outlet />
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default App;
